<template>
  <h1 class="title">
    <span>It's the Pit's<br /></span>BBQ
  </h1>
</template>

<style>
.splash {
  background: url("@/assets/brisket.jpeg");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-attachment: fixed;
  filter: brightness(35%);
}

h1 {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text !important;
  -moz-text-fill-color: transparent;
  -moz-background-clip: text !important;
  vertical-align: middle;
  font-size: 23vw;
  color: transparent;
  background: url("../assets/brisket.jpeg");
  background-repeat: no-repeat;
  background-size: cover !important;
}

span {
  margin-left: -6vw;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  -webkit-text-fill-color: antiquewhite;
  text-transform: initial;
  font-weight: 100;
  font-size: 9.5vw;
  text-align: center;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  line-height: 0.8;
}
</style>
