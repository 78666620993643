<template>
  <div class="surround">
    <h2>Contact Us</h2>
    <h3>It's the Pit's Barbeque</h3>
    <p>David Pitman</p>
    <p>11 Cedar Street</p>
    <p>Beverly, Massachusetts 01915</p>
    <p>978-317-7607</p>
    <p>
      <a href="https://www.facebook.com/ItsThePitsBbq" target="_blank"
        >VIEW FACEBOOK PAGE
      </a>
    </p>
  </div>
</template>
